<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Ganaderia extensiva</h1>
      <p>
        Como nuestro concepto es imitar la naturaleza, observamos cuidadosamente cómo puede ser un entorno natural para
        nuestros animales de granja y dársela para su bienestar. Los animales pueden vagar por nuestra tierra como
        rebaño o manada. Hay caminos para animales que les dan acceso a zonas donde pueden pastar y tambien levantar el
        suelo según sus instintos naturales. Estos caminos también los guían hacia fuentes de agua o lugares donde hay
        disponibilidad de alimento suplementario.
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2021-04-19_10-41-20.jpg">
        <img src="../../assets/images/2021-04-19_10-41-20.jpg">
      </picture>

      <p>
        Aunque el reglamento de la UE 2018/848 sobre producción ecológica permite el uso de piensos ecológicos de forma
        indefinida, seguimos la intención de dicho reglamento y hacemos un esfuerzo por alimentar a los animales de la
        tierra en la que están viviendo. Es importante de lograr ese objetivo ya que estamos bajo la amenaza de
        <router-link :to="{ name: 'desertification' }"> desertificación</router-link> pero son los mismos animales los
        que pueden ayudar con eso cuando se manejan bien. Vea a continuación lo que damos a los <a
          href="#feed_for_pigs">cerdos</a> y al <a href="#feed_for_cattle">ganado bovino</a> cuando todavía no
        crece suficiente en la tierra.
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-05-18_13-18-34.jpg">
        <img src="../../assets/images/2022-05-18_13-18-34.jpg">
      </picture>

      <h2>Productos de origin animal</h2>
      <p>
        Los animales no son nuestro producto principal. Los vemos como parte del ecosistema que pretendemos restaurar y
        por lo tanto, su función dentro del sistema es mucho más valiosa para nosotros que una posible venta. Por esta
        razón nuestro objetivo es mantener a cada animal el mayor tiempo posible dentro del sistema.
      </p>
      <p>
        Sin embargo, la muerte también forma parte de cualquier ecosistema. Por ejemplo, en una manada de bovinos los
        toros matarán a otro miembro de la manada que está sangrando para proteger a la manada de los depredadores. Los
        animales también se enferman, se rompen una pata y no existe cura. En la mayoría de los casos, los humanos
        pueden comer la carne de estos animales. No siempre es necesario matar activamente animales sanos para obtener
        carne, a menos que la producción y venta de carne sean el objetivo principal de la operacion.
      </p>
      <p>
        Estamos trabajando con las autoridades locales para que se nos permita usar una forma indolora de sacrificio en
        la granja según regulaciones de la Unión Europea en lugar de transportar animales en camiones a un enorme
        matadero a horas de distancia. Esto es un proceso complicado ya que involucra no solo a las autoridades del
        departamento de agricultura sino también a quienes trabajan para la salud pública. Lo que complica aún más esto
        es el hecho de que las reglas están hechas para grandes industrias carnicas.
      </p>
      <p>
        Dado que estamos bajo la amenaza de <router-link
          :to="{ name: 'desertification' }">desertificación</router-link>, usamos el impacto animal para sanar la tierra
        y hacerla más fértil según las formas descritas bajo el paraguas de <em>Gestión holística</em> y las enseñanzas
        de Alan Savory y otros.
      </p>

      <YouTubeLink />

      <h2>Alimentación suplementaria</h2>
      <p>
        Cuando no tenemos suficiente forraje para los animales, compramos alimento de afuera. Puedes ver lo que hay en
        el. Hay algunas vitaminas en el alimento, pero no hay vitamina B12.
      </p>
      <h3 id="feed_for_pigs">Pienso suplementario para cerdos ibéricos</h3>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-piensoCerdoIberico.jpg">
        <img width="100%" src="../../assets/images/piensoCerdoIberico.jpg">
      </picture>
      <h3 id="feed_for_cattle">Pienso suplementario para ganado bovino</h3>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-piensoBovinoExtensivo.jpg">
        <img width="100%" src="../../assets/images/piensoBovinoExtensivo.jpg">
      </picture>


    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import YouTubeLink from '@/components/YouTubeLink.vue'

export default {
  name: "Animals",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
